<template>
  <div class="giga-statistic_widget">
    <div class="giga-widget_body statistic-multi_row">
      <b-button :to="{ name: URL }" class="giga-widget_seeMore">{{ctaText}}</b-button>
      <div class="giga-statistic_row">
        <div class="giga-statistic_value">{{value}}</div>
        <div class="giga-statistic_label">{{label}}</div>
      </div>
      <div v-if="volumeCumac" class="giga-statistic_row">
        <div class="giga-statistic_value">{{this.convertedVolume | formatNumber}}</div>
        <div class="giga-statistic_label">Volume total (MWh cumac)</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "StatisticWidget",
  props: {
    value: {
      type: Number,
    },
    label: {
      type: String,
    },
    volumeCumac:{
      type: Boolean,
      default: false
    },
    cumacValue:{
      type: Number,
    },
    ctaText: {
      type: String,
    },
    URL: {
      type: String,
    },
  },
  computed: {
    convertedVolume() {
      // convert volume from Kwh to MWh
      return Math.floor(this.cumacValue / 1000);
    }
  }
};
</script>